import { ExternalLink, Image, Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { SupportArticleLinks, SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useState } from "react";

import { useReferralBonuses } from "./api/useReferralBonuses";
import { useReferralProfiles } from "./api/useReferralProfiles";
import { ActiveReferralBonus } from "./components/ActiveReferralBonus";
import { ReferralBonusSelector } from "./components/ReferralBonusSelector";
import { ShareReferralCodeFooter } from "./components/ShareReferralCodeFooter";
import { getSelectedMsaOption } from "./utils/getSelectedMsaOption";

export function WorkerReferralsPage() {
  const [licenseValueId, setLicenseValueId] = useState<string>("");
  const [msaValueId, setMsaValueId] = useState<string>("");

  useLogEffect(APP_V2_USER_EVENTS.VIEWED_WORKER_TO_WORKER_REFERRAL_SCREEN);
  const worker = useDefinedWorker();
  const {
    data: referralBonuses,
    isSuccess: referralBonusesIsLoaded,
    isLoading: referralBonusesIsLoading,
  } = useReferralBonuses();

  const { userId: workerId, referralCode } = worker;

  const { data: referralProfile } = useReferralProfiles(
    { workerId: workerId ?? "" },
    { enabled: isDefined(workerId) }
  );

  const currentReferralBonusValue = referralBonuses?.data.find(
    (referralBonuses) => referralBonuses.id === msaValueId
  );

  const selectedReferralBonus = getSelectedMsaOption({
    referralBonuses: referralBonuses?.data ?? [],
    msaOptionValue: currentReferralBonusValue,
    preferredMsa: referralProfile?.data.attributes.preferredMsa,
  });

  const { compensationPerLicense = {} } = selectedReferralBonus?.attributes ?? {};

  const selectedLicenseId = isDefined(compensationPerLicense?.[licenseValueId])
    ? licenseValueId
    : Object.keys(compensationPerLicense)[0] ?? "";

  const bonusAmountInUsd =
    selectedReferralBonus?.attributes.compensationPerLicense[selectedLicenseId];
  const bonusType = selectedReferralBonus?.attributes?.program;
  const userCanSeeBonusCard =
    isDefined(bonusAmountInUsd) && bonusAmountInUsd > 0 && isDefined(bonusType);

  const userCanShareReferralCode =
    userCanSeeBonusCard && isDefined(referralCode) && isDefined(selectedReferralBonus);

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Refer Professionals"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.ACCOUNT} />}
        />
      }
      containerMaxWidth="lg"
    >
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack spacing={1}>
            <Title bold component="h1">
              Share and earn nationwide!
            </Title>
            <Text>Refer your friends and get paid!</Text>
            <Text>
              <ExternalLink
                to={SupportArticleLinks.REFERRAL_BONUS}
                underline="hover"
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                    articleLink: SupportArticleLinks.REFERRAL_BONUS,
                    articleName: SupportArticles.REFERRAL_BONUS,
                    supportContext: SupportContext.WORKER_REFERRALS,
                  });
                }}
              >
                Learn more here
              </ExternalLink>
            </Text>
          </Stack>
          <Image src={cbhBunnyLogo} alt="Clipboard Bunny" width={100} />
        </Stack>

        {referralBonusesIsLoading && (
          <>
            <Skeleton width="50%" />
            <Skeleton />
            <Skeleton />
          </>
        )}

        {referralBonusesIsLoaded && (
          <ReferralBonusSelector
            referralBonuses={referralBonuses.data}
            selectedMsaOption={selectedReferralBonus}
            setLicenseValueId={setLicenseValueId}
            setMsaValueId={setMsaValueId}
            selectedLicenseId={selectedLicenseId}
          />
        )}

        {userCanSeeBonusCard && (
          <ActiveReferralBonus bonusAmountInUsd={bonusAmountInUsd} bonusType={bonusType} />
        )}
      </Stack>
      {userCanShareReferralCode && (
        <Card raised square>
          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <ShareReferralCodeFooter
              referralCode={referralCode}
              selectedLicenseId={selectedLicenseId}
              referralBonus={selectedReferralBonus}
            />
          </CardContent>
        </Card>
      )}
    </PageWithHeader>
  );
}
